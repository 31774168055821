import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import {
  IconEdit,
  IconTrash,
} from '@tabler/icons-react';
import * as api from '../api/index.js'

interface HeaderProps {
  showSuccess?: (content: string) => void;
  showError?: (content: string) => void;
}

const Header: React.FC<HeaderProps> = ({ showSuccess, showError }) => {
  const { user, logout } = useAuth0();
  const [visible, setVisible] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [isEditingCompany, setIsEditingCompany] = useState(false);
  const [editedUsername, setEditedUsername] = useState('');
  const [editedCompany, setEditedCompany] = useState('');
  const menuHeader = useRef<any>(null);
  let items = [
    {
      label: 'My Profile',
      icon: 'pi pi-cog',
      command: () => {
        setVisible(true);
      }
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => {
        handleLogout();
      }
    }
  ];
	
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await api.getUserInfo(user?.sub);
        setUserInfo(response.data);
        setEditedUsername(response.data?.username)
        setEditedCompany(response.data?.company)
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    if (user?.sub) {
      fetchUserInfo();
    }
    console.log('============user', user)
  }, [user]);

	const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: 'https://www.atlaspro.ai',
      },
    });
  };

  const handleSubscription = async () => {
    try {
      const response= await api.getCheckoutUrl(user?.sub)
      if(response.data.url){
        window.location.href = response.data.url
      }

    } catch (error) {
      console.error('Error creating Stripe Checkout Session:', error);
    }
  };

  const getAvatarInitials = (name: string)=> {
    // Check if the name is an email
    if (name.includes('@')) {
      const username = name.split('@')[0];
      return username.slice(0, 2).toUpperCase();
    } 
    // Handle regular names (including multi-word names)
    else {
      const names = name.split(' '); // Split by spaces
      const initials = names.map(n => n.charAt(0).toUpperCase()).join(''); // Get first letter of each name
      return initials.slice(0, 2); // Return the first two initials
    }
  }

  const handleSaveUsername = async() => {
    const response = await api.updateUserProfile(user?.sub, {username: editedUsername});
    if(response.status === 200){
      if(showSuccess)
        showSuccess(`You've successfully updated your username!`)
      const new_response = await api.getUserInfo(user?.sub);
        setUserInfo(new_response.data);
    }else{
      if(showError)
        showError(`There exists some internal error. Please try again later`)
    }
    setIsEditingUsername(false)
  }

  const handleSaveCompany = async() => {
    const response = await api.updateUserProfile(user?.sub, {company: editedCompany});
    if(response.status === 200){
      if(showSuccess)
        showSuccess(`You've successfully updated your company name!`)
      const new_response = await api.getUserInfo(user?.sub);
        setUserInfo(new_response.data);
    }else{
      if(showError)
        showError(`There exists some internal error. Please try again later.`)
    }
    setIsEditingCompany(false)
  }

	return (
		<header
			className="flex items-center justify-between map_sub_container"
			style={{ height: '10%', background: '#12221f' }}
		>
			<div className="flex items-center">
				<a href="/">
					<img
						// src="https://www.dropbox.com/scl/fi/ensej1l64crnkpsmy2kbi/atlaspro-light-logo-1.png?rlkey=t18h2pq0lez222klradjj8fy9&raw=1"
						src="/atlaspro_logo.png"
						alt="Atlas Pro Intelligence Logo"
						className="mx-auto" // Adjust the class as needed for styling
						width="100%"
						style={{ maxWidth: '130px' }}
					/>
				</a>
			</div>
      <div className='flex items-center'>
        <div className="flex card justify-content-center" style={{boxShadow:'0 0 0 !important'}}>
          <Menu model={items} popup ref={menuHeader} id="popup_header_menu"style={{marginTop:'5px'}} />
          {/* <img
            src="/avatar.png"
            alt="avatar"
            className="menu-button left_bar_icon"
            style={{width: '27px', height: '27px'}}
            onClick={(event) => {menuHeader.current.toggle(event)}} aria-controls="popup_header_menu" aria-haspopup
          /> */}
          <div
            style={{color: 'white', borderRadius: '20px', background: '#3b4644', cursor: 'pointer'}}
            className='items-center px-3 py-1 mx-3'
            onClick={(event) => {menuHeader.current.toggle(event)}} aria-controls="popup_header_menu" aria-haspopup
          >
            <Avatar label={getAvatarInitials(user?.name || 'Test User')} style={{ backgroundColor: '#12221f' }} shape="circle" />
            <i className='ml-2 pi pi-angle-down' style={{verticalAlign: 'text-bottom'}}></i>
          </div>
        </div>
        <div style={{color: 'white', border: '1px solid #5c5c5c', cursor: 'pointer'}} className='items-center px-3 py-1 ml-1'>
          <span>Share</span>
          <i className='ml-2 pi pi-arrow-up-right' style={{verticalAlign: 'text-bottom'}}></i>
        </div>
        {/* <div style={{color: 'white', border: '1px solid #5c5c5c', cursor: 'pointer'}} className='items-center px-3 py-1'>
          <span>84%</span>
          <i className='ml-2 pi pi-angle-down' style={{verticalAlign: 'text-bottom'}}></i>
        </div> */}
        {/* <a href={`https://app.atlaspro.ai/${localStorage.getItem('Address')}`} style={{marginLeft:'10px',marginRight:'10px'}}>
          <img
            src="wand.png"
            alt="Magical Wand Image"
            className="mx-auto" // Adjust the class as needed for styling
            width="40px"
            // style={{ maxWidth: '150px' }}
          />
        </a> */}
      </div>
      <Dialog header="My Profile" visible={visible} style={{ width: '500px', background: '#00211D' }} onHide={() => {if (!visible) return; setVisible(false); }}>
        <div className="flex" style={{paddingBottom: '20px', gap: '25px', alignItems: 'center'}}>
          {/* <img
            src="/avatar.png"
            alt="avatar"
            style={{width: '150px', height: '150px'}}
          /> */}
          <Avatar size="xlarge" label={getAvatarInitials(user?.name || 'Test User')} style={{ backgroundColor: '#12221f' }} shape="circle" />
          <div className='profile-right' style={{rowGap: '15px', width: '350px'}}>
            <div className='flex' style={{justifyContent: 'space-between', marginRight: '35px', alignItems: 'center'}}>
              <div>
                <p style={{width: '270px'}}>Name:</p>
                <p style={{marginLeft: '15px', marginTop: '3px'}}>
                  {!isEditingUsername?(
                    userInfo?.username
                  ):(
                    <input type='text'
                      style={{background: 'none', border: '1px solid white', padding: '2px 2px 2px 4px', borderRadius: '6px', width: '270px'}}
                      value={editedUsername}
                      onChange={(e)=>setEditedUsername(e.target.value)}
                    />
                  )}
                </p>
              </div>
              <div className="flex flex-col items-center justify-end gap-4 ml-1 md:-mr-8 md:ml-0 md:flex-row md:gap-1 md:items-start md:justify-start">
                {isEditingUsername &&
                  <button
                    className="mr-1 text-gray-400 group-hover:visible focus:visible hover:text-gray-300 dark:text-gray-400"
                    onClick={handleSaveUsername}
                  >
                    <i className="pi pi-save"></i>
                  </button>
                }
                <button
                  className="text-gray-400 group-hover:visible focus:visible hover:text-gray-300 dark:text-gray-400"
                  onClick={()=>setIsEditingUsername(prev => !prev)}
                >
                  {!isEditingUsername?(
                    <IconEdit size={20} />
                  ):(
                    <i className="pi pi-undo"></i>
                  )}
                </button>
              </div>
            </div>
            <div>
              <p style={{width: '270px'}}>Email: </p>
              <p style={{marginLeft: '15px', marginTop: '3px'}}>{userInfo?.email}</p>
            </div>
            <p style={{width: '270px'}}>Plan:&nbsp;
              <span>
                {userInfo && userInfo.plan=== 'Pro' && 
                  `Pro`
                }
                {userInfo && userInfo.plan=== 'Free Trial' && 
                  <>
                    Free Trial &nbsp;&nbsp;<span className='subscribe' onClick={handleSubscription}>Subscribe</span>
                  </>
                }
                {userInfo && userInfo.plan=== 'Paused' && 
                  <>
                    Trial ended &nbsp;&nbsp;<span className='subscribe' onClick={handleSubscription}>Subscribe</span>
                  </>
                }
              </span>
            </p>
            <div className='flex' style={{justifyContent: 'space-between', marginRight: '35px', alignItems: 'center'}}>
              <div>
                <p style={{width: '270px'}}>Company:</p>
                <p style={{marginLeft: '15px', marginTop: '3px'}}>
                  {!isEditingCompany?(
                    userInfo?.company
                  ):(
                    <input
                      type='text'
                      style={{background: 'none', border: '1px solid white', padding: '2px 2px 2px 4px', borderRadius: '6px', width: '270px'}}
                      value={editedCompany}
                      onChange={(e)=>setEditedCompany(e.target.value)}
                    />
                  )}
                </p>
              </div>
              <div className="flex flex-col items-center justify-end gap-4 ml-1 md:-mr-8 md:ml-0 md:flex-row md:gap-1 md:items-start md:justify-start">
                {isEditingCompany &&
                  <button
                    className="mr-1 text-gray-400 group-hover:visible focus:visible hover:text-gray-300 dark:text-gray-400"
                    onClick={handleSaveCompany}
                  >
                    <i className="pi pi-save"></i>
                  </button>
                }
                <button
                  className="text-gray-400 group-hover:visible focus:visible hover:text-gray-300 dark:text-gray-400"
                  onClick={()=>setIsEditingCompany(prev => !prev)}
                >
                  {!isEditingCompany?(
                    <IconEdit size={20} />
                  ):(
                    <i className="pi pi-undo"></i>
                  )}
                </button>
              </div>
            </div>
            </div>
        </div>
        {/* <div style={{float: 'right', marginTop: '5px'}}>
          <Button label="Close" onClick={()=> setVisible(false)} />
        </div> */}
      </Dialog>
		</header>
	)
}

export default Header;