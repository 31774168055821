export const parcel_fields_from_regrid = [
  { field: 'parcelnumb',  label: 'Parcel ID' },
  { field: 'address',  label: 'Parcel Address' },
  { field: 'scity',  label: 'Parcel Address City' },
  { field: 'county',  label: 'Parcel Address County' },
  { field: 'state2',  label: 'Parcel Address State' },
  { field: 'szip5',  label: '5 Digit Parcel Zip Code' },
  { field: 'owner',  label: 'Owner Name' },
  // { field: 'owner_email',  label: 'Owner Email' },
  // { field: 'owner_phone',  label: 'Owner Phone' },
  { field: 'owner2',  label: 'Second Owner Name' },
  // { field: 'owner2_email',  label: 'Second Owner Email' },
  // { field: 'owner2_phone',  label: 'Second Owner Phone' },
  { field: 'll_address_count',  label: 'Total Address Count' },
  { field: 'lat',  label: 'Latitude' },
  { field: 'lon',  label: 'Longitude' },
  { field: 'usecode',  label: 'Parcel Use Code' },
  { field: 'zoning',  label: 'Zoning Code' },
  { field: 'zoning_description',  label: 'Zoning Description' },
  { field: 'zoning_type',  label: 'Zoning Type' },
  { field: 'zoning_subtype',  label: 'Zoning Subtype' },
  { field: 'yearbuilt',  label: 'Structure Year Built' },
  { field: 'legaldesc',  label: 'Legal Description' },
  { field: 'gisacre',  label: 'County-Provided Acres' },
  { field: 'lbcs_activity',  label: 'Land Use Code: Activity' },
  { field: 'lbcs_activity_desc',  label: 'Land Use Code Description: Activity' },
  { field: 'lbcs_site',  label: 'Land Use Code: Site' },
  { field: 'lbcs_site_desc',  label: 'Land Use Code Description: Site' },
  // { field: 'ogc_fid', label: 'Object ID' },
  // { field: 'geoid',  label: 'FIPS Code' },
  // { field: 'address2',  label: 'Parcel Address Second Line' },
  // { field: 'szip',  label: 'Parcel Address Zip Code' },
  // { field: 'owntype',  label: 'Owner Type' },
  // { field: 'ownfrst',  label: 'Owner First Name' },
  // { field: 'ownlast',  label: 'Owner Last Name' },
  // { field: 'owner3',  label: 'Third Owner Name' },
  // { field: 'owner4',  label: 'Fourth Owner Name' },
  // { field: 'previous_owner',  label: 'Previous Owner Name' },
  // // { field: 'parcelnumb_no_formatting',  label: 'Parcel ID without Formatting' },
  // { field: 'saleprice',  label: 'Last Sale Price ($)' },
  // { field: 'saledate',  label: 'Last Sale Date' },
  // { field: 'parvaltype',  label: 'Parcel Value Type' },
  // { field: 'parval',  label: 'Total Parcel Value ($)' },
  // { field: 'improvval',  label: 'Improvement Value ($)' },
  // { field: 'landval',  label: 'Land Value ($)' },
  // { field: 'taxamt',  label: 'Annual Tax Bill' },
  // { field: 'taxyear',  label: 'Tax Year' },
  // { field: 'usedesc',  label: 'Parcel Use Description' },
  // { field: 'zoning_code_link',  label: 'Zoning Code Link' },
  // { field: 'zoning_id',  label: 'Zoning Area ID' },
  // { field: 'struct',  label: 'Structure on Parcel' },
  // { field: 'structno',  label: 'Number of Structures on Parcel' },
  // { field: 'numstories',  label: 'Number of Stories' },
  // { field: 'numunits',  label: 'Number of Living Units' },
  // { field: 'numrooms',  label: 'Number of Rooms' },
  // { field: 'structstyle',  label: 'Structure Style' },
  // { field: 'agval',  label: 'Agricultural Value' },
  // { field: 'homestead_exemption',  label: 'Homestead Exemption' },
  // { field: 'state_parcelnumb',  label: 'State Provided Parcel ID Number' },
  // { field: 'account_number',  label: 'Parcel Account Number' },
  // { field: 'tax_id',  label: 'Parcel Tax Identification Number' },
  // { field: 'alt_parcelnumb1',  label: 'First Alternative Parcel ID' },
  // { field: 'alt_parcelnumb2',  label: 'Second Alternative Parcel ID' },
  // { field: 'alt_parcelnumb3',  label: 'Third Alternative Parcel ID' },
  // { field: 'mailadd',  label: 'Mailing Address' },
  // { field: 'mail_city',  label: 'Mailing Address City' },
  // { field: 'mail_country',  label: 'Mailing Address Country' },
  // { field: 'mail_state2',  label: 'Mailing Address State' },
  // { field: 'mail_address2',  label: 'Mailing Address Second Line' },
  // { field: 'careof',  label: 'Mailing Address Care Of' },
  // { field: 'mail_addno',  label: 'Mailing Address Street Number' },
  // { field: 'mail_addpref',  label: 'Mailing Address Street Prefix' },
  // { field: 'mail_addstr',  label: 'Mailing Address Street Name' },
  // { field: 'mail_addsttyp',  label: 'Mailing Address Street Type' },
  // { field: 'mail_addstsuf',  label: 'Mailing Address Street Suffix' },
  // { field: 'mail_unit',  label: 'Mailing Address Unit Number' },
  // { field: 'mail_zip',  label: 'Mailing Address ZIP Code' },
  // // { field: 'mail_urbanization',  label: 'Mailing Address Urbanizacion (Puerto Rico)' },
  // // { field: 'saddno',  label: 'Parcel Address Number' },
  // { field: 'saddpref',  label: 'Parcel Address Prefix' },
  // { field: 'saddstr',  label: 'Parcel Address Street Name' },
  // { field: 'saddsttyp',  label: 'Parcel Address Street Type' },
  // { field: 'saddstsuf',  label: 'Parcel Address Street Suffix' },
  // { field: 'sunit',  label: 'Parcel Address Unit' },
  // // { field: 'original_address',  label: 'Original Parcel Address' },
  // { field: 'city',  label: 'US Census County Subdivision' },
  // // { field: 'urbanization',  label: 'Parcel Urbanizacion' },
  // { field: 'location_name',  label: 'Location Name' },
  // { field: 'address_source',  label: 'Primary Address Source' },
  // { field: 'plat',  label: 'Plat' },
  // { field: 'book',  label: 'Book' },
  // { field: 'page',  label: 'Page' },
  // { field: 'block',  label: 'Block' },
  // { field: 'lot',  label: 'Lot' },
  // { field: 'neighborhood',  label: 'Neighborhood' },
  // { field: 'subdivision',  label: 'Subdivision' },
  // { field: 'fema_flood_zone',  label: 'FEMA Flood Zone' },
  // { field: 'fema_flood_zone_subtype',  label: 'FEMA Flood Zone Subtype' },
  // // { field: 'fema_flood_zone_raw',  label: 'FEMA Flood Zone Raw Data' },
  // { field: 'fema_flood_zone_data_date',  label: 'FEMA Flood Zone Data Date' },
  // { field: 'qoz',  label: '	Federal Qualified Opportunity Zone' },
  // { field: 'qoz_tract',  label: 'Qualified Opportunity Zone Tract Number' },
  // { field: 'census_tract',  label: 'Census 2020 Tract' },
  // { field: 'census_block',  label: 'Census 2020 Block' },
  // { field: 'census_blockgroup',  label: 'Census 2020 Blockgroup' },
  // { field: 'census_zcta',  label: 'Census Zip Code Tabulation Area' },
  // { field: 'census_elementary_school_district',  label: 'Census Provided Elementary School District' },
  // { field: 'census_secondary_school_district',  label: 'Census Provided Secondary School District' },
  // { field: 'census_unified_school_district',  label: 'Census Provided Unified School District' },
  // { field: 'll_last_refresh',  label: 'Last County Refresh Date' },
  // { field: 'sourceurl',  label: 'Source URL' },
  // { field: 'recrdareatx',  label: 'Recorded Area (text)' },
  // { field: 'recrdareano',  label: 'Total Square Footage of Structures' },
  // { field: 'sqft',  label: 'County-Provided Parcel Square Feet' },
  // { field: 'll_gisacre',  label: 'Parcel Acres' },
  // { field: 'll_gissqft',  label: 'Parcel Square Feet' },
  // { field: 'll_bldg_footprint_sqft',  label: 'Building Footprint Square Feet' },
  // { field: 'll_bldg_count',  label: 'Building Count' },
  // { field: 'cdl_raw',  label: 'Cropland Data Layer Raw Values' },
  // { field: 'cdl_majority_category',  label: 'Cropland Data Layer Majority Category' },
  // { field: 'cdl_majority_percent',  label: 'Cropland Data Layer Majority Percent' },
  // { field: 'cdl_date',  label: 'Cropland Data Layer Date' },
  // { field: 'plss_township',  label: 'PLSS Township' },
  // { field: 'plss_section',  label: 'PLSS Section' },
  // { field: 'plss_range',  label: 'PLSS Range' },
  // { field: 'reviseddate',  label: 'Date of Last Revision' },
  // { field: 'path',  label: 'Parcel Path' },
  // { field: 'll_stable_id',  label: 'Stable ID Status' },
  // { field: 'll_uuid',  label: 'UUID' },
  // { field: 'll_stack_uuid',  label: 'Parcel Stack UUID' },
  // { field: 'll_row_parcel',  label: 'Right-of-Way Parcel Flag' },
  // { field: 'll_updated_at',  label: 'Last Modified' },
  // { field: 'placekey',  label: 'Placekey' },
  // { field: 'dpv_status',  label: 'USPS Delivery Point Validation' },
  // { field: 'dpv_codes',  label: 'Delivery Point Validation Codes' },
  // { field: 'dpv_notes',  label: 'Delivery Point Validation Notes' },
  // { field: 'dpv_type',  label: 'Delivery Point Match Type' },
  // { field: 'cass_errorno',  label: 'CASS Error Codes' },
  // { field: 'rdi',  label: 'Residential Delivery Indicator' },
  // { field: 'usps_vacancy',  label: 'USPS Vacancy Indicator' },
  // { field: 'usps_vacancy_date',  label: 'USPS Vacancy Indicator Date' },
  // { field: 'padus_public_access',  label: 'PAD-US Public Access Designation' },
  // { field: 'lbcs_function',  label: 'Land Use Code: Function' },
  // { field: 'lbcs_function_desc',  label: 'Land Use Code Description: Function' },
  // { field: 'lbcs_structure',  label: 'Land Use Code: Structure' },
  // { field: 'lbcs_structure_desc',  label: 'Land Use Code Description: Structure' },
  // { field: 'lbcs_ownership',  label: 'Land Use Code: Ownership' },
  // { field: 'lbcs_ownership_desc',  label: 'Land Use Code Description: Ownership' },
]

export const default_parcelInfo = {
  "ogc_fid": "20612",
  "geoid": "13051",
  "parcelnumb": "40004 27004",
  "parcelnumb_no_formatting": "4000427004",
  "state_parcelnumb": null,
  "account_number": null,
  "tax_id": null,
  "alt_parcelnumb1": null,
  "alt_parcelnumb2": null,
  "alt_parcelnumb3": null,
  "usecode": "R3",
  "usedesc": null,
  "zoning": "R-2",
  "zoning_description": "One And Two Family Residential",
  "zoning_type": "Residential",
  "zoning_subtype": "Two Family",
  "zoning_code_link": "https://library.municode.com/ga/tybee_island/codes/code_of_ordinances?nodeId=PTIICOOR_APXALADECO_ART4ZODI_S4-010LIDI",
  "zoning_id": 11823118,
  "struct": null,
  "structno": null,
  "yearbuilt": 1997,
  "numstories": null,
  "numunits": null,
  "numrooms": null,
  "structstyle": null,
  "parvaltype": "FAIR MARKET",
  "improvval": 299000,
  "landval": 168800,
  "parval": 467800,
  "agval": null,
  "homestead_exemption": null,
  "saleprice": 140000,
  "saledate": 881539200000,
  "taxamt": null,
  "taxyear": null,
  "owntype": null,
  "owner": "HAYSER LEO M & BARBARA J",
  "ownfrst": null,
  "ownlast": null,
  "owner2": null,
  "owner3": null,
  "owner4": null,
  "previous_owner": null,
  "mailadd": "501 5TH ST",
  "mail_address2": null,
  "careof": null,
  "mail_addno": null,
  "mail_addpref": null,
  "mail_addstr": null,
  "mail_addsttyp": null,
  "mail_addstsuf": null,
  "mail_unit": null,
  "mail_city": "TYBEE ISLAND",
  "mail_state2": "GA",
  "mail_zip": "31328",
  "mail_country": null,
  "mail_urbanization": null,
  "address": "501 5TH ST",
  "address2": null,
  "saddno": "501",
  "saddpref": null,
  "saddstr": "5TH",
  "saddsttyp": "ST",
  "saddstsuf": null,
  "sunit": null,
  "scity": "TYBEE ISLAND",
  "original_address": "{\"address\":\"501 5TH ST\",\"saddno\":\"501\",\"saddstr\":\"5TH\",\"saddsttyp\":\"ST\",\"sunit\":\"\",\"scity\":\"TYBEE ISLAND\",\"szip\":\"31328\"}",
  "city": "tybee-island-wilmington-island",
  "county": "chatham",
  "state2": "GA",
  "szip": "31328-9627",
  "szip5": "31328",
  "urbanization": null,
  "ll_address_count": 1,
  "location_name": null,
  "address_source": "county;cass",
  "legaldesc": "LOT 3 RECOMBINATION OF LOTS 160A & B AND 171A & B",
  "plat": null,
  "book": "189V",
  "page": "597",
  "block": null,
  "lot": null,
  "neighborhood": null,
  "subdivision": null,
  "lat": "32.007609",
  "lon": "-80.846549",
  "fema_flood_zone": "AE",
  "fema_flood_zone_subtype": null,
  "fema_flood_zone_raw": "[{\"zone\":\"AE\",\"subtype\":null,\"percent\":100}]",
  "fema_flood_zone_data_date": 1697500800000,
  "qoz": "No",
  "qoz_tract": null,
  "census_tract": "13051011111",
  "census_block": "130510111112007",
  "census_blockgroup": "130510111112",
  "census_zcta": "31328",
  "census_elementary_school_district": null,
  "census_secondary_school_district": null,
  "census_unified_school_district": "Chatham County School District",
  "ll_last_refresh": 1678752000000,
  "sourceurl": null,
  "recrdareatx": null,
  "recrdareano": null,
  "gisacre": 0.1106,
  "sqft": null,
  "ll_gisacre": 0.11054,
  "ll_gissqft": "4815",
  "ll_bldg_footprint_sqft": 1905,
  "ll_bldg_count": 1,
  "cdl_raw": null,
  "cdl_majority_category": null,
  "cdl_majority_percent": null,
  "cdl_date": null,
  "plss_township": null,
  "plss_section": null,
  "plss_range": null,
  "reviseddate": 1652054400000,
  "path": "/us/ga/chatham/tybee-island-wilmington-island/20612",
  "ll_stable_id": "preserved",
  "ll_uuid": "c1a82fee-6ae0-4aa2-b109-ff6597674c3a",
  "ll_stack_uuid": null,
  "ll_row_parcel": null,
  "ll_updated_at": 1708055188000,
  "placekey": "225@8gd-q5z-yy9",
  "dpv_status": "V",
  "dpv_codes": "YNNNN",
  "dpv_notes": "AABB",
  "dpv_type": "S",
  "cass_errorno": "14.7,A1",
  "rdi": "Y",
  "usps_vacancy": "N",
  "usps_vacancy_date": 1704326400000,
  "padus_public_access": null,
  "lbcs_activity": "1100",
  "lbcs_activity_desc": "Household",
  "lbcs_function": "1100",
  "lbcs_function_desc": "Private household",
  "lbcs_structure": "1000",
  "lbcs_structure_desc": "Residential buildings",
  "lbcs_site": "6000",
  "lbcs_site_desc": "Developed site with buildings",
  "lbcs_ownership": null,
  "lbcs_ownership_desc": null,
  "id": 35061829
}

export const FCC_fields = [
  { field: 'AvgDown',  label: 'Average Download Speed (Mbps)' },
  { field: 'AvgLat',  label: 'Average Latency (ms)' },
  { field: 'AvgUp',  label: 'Average Upload Speed (Mbps)' },
  { field: 'QuadKey',  label: 'Quadtree Key' },
  { field: 'CalQuarter',  label: 'Quarter' },
  // { field: 'OBJECTID',  label: 'OBJECTID' },
  { field: 'Shape__Area',  label: 'Shape__Area' },
  { field: 'Shape__Length',  label: 'Shape__Length' },
  { field: 'Tests',  label: 'Tests Taken' },
  { field: 'Devices',  label: 'Unique Devices' },
  { field: 'CalYear',  label: 'Year' },
  { field: 'Existing Provider',  label: 'Existing Provider' }
]